<template>
  <v-col
    cols="12"
    class="document-page-preview pa-3 pb-0"
  >
    <v-hover
      v-slot="{ hover }"
      :disabled="isLoading"
    >
      <v-card
        class="document-page-preview__page mx-auto"
        :class="{ 'document-page-preview__page--empty': isLoading }"
        :disabled="isLoading"
        :elevation="hover ? 5 : 0"
        rounded="lg"
        @click="scrollToPage(pageNumber)"
      >
        <div :id="`document-page-preview-${pageNumber}`"></div>
      </v-card>
    </v-hover>
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'DocumentPagePreview',
  props: {
    pageNumber: {
      type: Number,
      required: true,
      validation: (value) => {
        return 0 < value && value <= this.numberOfPages;
      },
    },
    pageId: {
      type: String,
      required: true,
      validation: (value) => {
        return value.includes(this.pageNumber);
      },
    },
  },
  computed: {
    ...mapGetters({
      document: 'pdfDocument/document',
      documentLink: 'pdfDocument/documentLink',
      numberOfPages: 'pdfDocument/numberOfPages',
    }),
    isLoading() {
      return 'undefined' === typeof this.document;
    },
  },
  methods: {
    scrollToPage() {
      const el = document.getElementById(this.pageId);

      if (el) {
        el.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.document-page-preview {
  z-index: 0;

  &__page {
    overflow: hidden;
    height: fit-content;

    &--empty {
      min-height: 7.8rem;
    }
  }
}
</style>
