<template>
  <div
    class="sidemenu-wrap"
    v-bind:class="{ openWrap: isSideMenuVisible }"
  >
    <div
      @click="hideSideMenu"
      class="sidemenu__close"
    ></div>

    <div
      class="sidemenu"
      v-bind:class="{ open: isSideMenuVisible }"
    >
      <div
        class="sidemenu__main-button"
        :class="{ 'sidemenu__main-button--disabled': isAccDisabled }"
      >
        <Button
          :type="mainButton.type"
          @click="$router.push({ name: mainButton.route, params: mainButton.routeParams })"
        >
          {{ mainButton.title }}
        </Button>
        <div :class="`sidemenu__` + mainButton.class">
          <IcoArrowBack v-if="mainButton.type === 'back'" />
          <IcoPlusRight v-else />
        </div>
      </div>

      <Note
        v-if="!profileWorkspaces.length"
        class="sidemenu__note-workspace"
      >
        <p>{{ $t('workspace.beforeFirstExists') }}</p>
      </Note>

      <div
        v-if="activeWorkspace"
        class="sidemenu__items-wrapper"
      >
        <div class="sidemenu__items">
          <SideMenuWorkplace :currentWorkspace="activeWorkspace" />
        </div>
      </div>

      <v-btn
        v-if="canShowAdvancedFunctionsLink"
        class="pa-5 sidemenu__function-button text-decoration-none mt-3"
        color="primary"
        href="https://signi.refined.site/space/PKNB/905838593"
        target="_blank"
        block
        outlined
      >
        <v-icon class="text-decoration-none">mdi-puzzle</v-icon>
        <span class="sidemenu__function-button--text text-decoration-none ml-2">{{
          $t('contract.extension_function')
        }}</span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Note from '@/components/Note.vue';
import IcoPlusRight from '@/components/svg/IcoPlus.vue';
import IcoArrowBack from '@/components/svg/IcoArrowBack';
import SideMenuWorkplace from '@/components/partials/SideMenuWorkplace.vue';

export default {
  name: 'SideMenu',
  components: {
    Note,
    IcoPlusRight,
    IcoArrowBack,
    SideMenuWorkplace,
  },
  props: {
    showMenu: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      windowWidth: 0,
    };
  },
  computed: {
    ...mapGetters({
      isSideMenuVisible: 'isSideMenuVisible',
      profileWorkspaces: 'profileWorkspaces',
      workspaceById: 'workspaceById',
    }),
    activeWorkspace() {
      return this.workspaceById(this.activeWorkspaceId);
    },
    activeWorkspaceId() {
      return this.$route?.params?.workspace_id || localStorage.getItem('workspace_id');
    },
    isAccDisabled() {
      return this.activeWorkspace?.owner_disabled;
    },
    canShowAdvancedFunctionsLink() {
      return !['workspaceSettings', 'account'].includes(this.$route.name);
    },
    mainButton() {
      if (this.$route.name === 'workspaceSettings' || this.$route.name === 'account') {
        return {
          class: 'back-icon',
          route: 'dashboard',
          routeParams: {
            workspace_id: this.activeWorkspaceId,
          },
          title: this.$t('contract.back_to_dashboard'),
          type: 'back',
        };
      }

      return {
        class: 'create-new-icon',
        route: 'createNew',
        routeParams: {
          workspace_id: this.activeWorkspaceId,
        },
        title: this.$t('contract.new_contract'),
        type: 'primary',
      };
    },
  },
  watch: {
    windowWidth() {
      this.checkMenu();
    },
  },
  created() {
    this.windowWidth = window.innerWidth;

    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.windowWidth = window.innerWidth;
        this.checkMenu();
      });
    });
  },
  methods: {
    checkMenu() {
      if (this.$vuetify.breakpoint.mobile || !this.showMenu) {
        this.hideSideMenu();
      } else if (this.showMenu) {
        this.$store.commit('openSideMenu');
      }
    },
    hideSideMenu() {
      this.$store.commit('hideSideMenu');
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/sass/mixins';

.sidemenu__function-button {
  box-sizing: border-box;
  border-radius: 5px;

  &--text {
    color: #7031b4;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 17.5px;

    &:hover {
      text-decoration: underline;
    }
  }
}

.sidemenu__close {
  display: none;
}

.sidemenu-wrap.openWrap {
  z-index: 21;
  position: fixed !important;
  top: 60px !important;
  bottom: 0;
  width: 300px;

  .sidemenu__close {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    @include md {
      display: block;
    }
  }

  @include md {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 21;
    background: rgba(0, 0, 0, 0.6);
  }
}

.main.coverMenu .sidemenu-wrap {
  opacity: 0;
}

.main.coverMenu .sidemenu-wrap.openWrap {
  opacity: 1;
  @include animation;
  width: 100%;
  height: 100%;
  // position: fixed;
  // top: 60px;
  left: 0;
  bottom: 0;
  z-index: 21;
  background: rgba(0, 0, 0, 0.6);
}

.main.coverMenu .sidemenu-wrap.openWrap .sidemenu__close {
  display: block !important;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.sidemenu {
  font-size: 16px;
  width: 311px;
  position: fixed;
  z-index: 20;
  padding: 80px 20px 20px 20px;
  top: 0;
  left: 0;
  bottom: 0;
  overflow-y: hidden;
  transform: translate(-100%, 0);
  @include animation();

  @include sm {
    transform: translate(-100%, 0);
  }

  @include md {
    background: white;
  }
  &__items-wrapper {
    border-radius: 6px;
    overflow: hidden;
    box-shadow: 0 7px 24px 0 rgba(142, 151, 164, 0.15);
  }
  &__items {
    max-height: calc(100vh - 145px);
    overflow-x: hidden;
    overflow-y: scroll;
    margin-right: -17px;
  }

  &.open {
    transform: translate(0, 0);
  }

  .button {
    width: 100%;
    box-shadow: none;
    height: 45px;
    text-align: left;
    padding-left: 50px;
    z-index: 0;
  }

  .sidemenu__create-new-icon svg g {
    fill: #fff;
    transition: 0.5s;
  }
}

.sidemenu__logo {
  @include md {
    display: none;
  }
}

.sidemenu__title {
  height: 60px;
  line-height: 60px;
  width: 100%;
  border-bottom: 1px solid #d4dff0;
  padding: 0 31px;
  position: relative;
  font-size: 24px;
  color: #8e97a4;
  letter-spacing: -0.05px;
  font-weight: 300;
  text-align: center;
}

.sidemenu__bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  background: #f2f5fc;
  z-index: 3;
  padding: 10px 0px 10px 0px;
}

.sidemenu__bottom a {
  color: #616161;
  font-weight: 600;
  font-size: 13px;
  letter-spacing: -0.03px;
  text-align: center;
  transition: 0.2s;
  &:hover {
    color: #5e239e;
    transition: 0.2s;
  }
}

.sidemenu:before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  display: inline-block;
  width: 40px;
  height: 22px;
  background-image: url(@/assets/images/contract-shape-top-left-desktop.png);
  background-repeat: none;
  background-size: cover;
  background-position: 0 0;
  z-index: 3;
}
.sidemenu__main-button {
  position: relative;

  &--disabled {
    pointer-events: none;
    opacity: 0.6;
  }
}
.sidemenu__create-new-icon {
  position: absolute;
  top: 14px;
  left: 25px;
}

.sidemenu__back-icon {
  position: absolute;
  color: #b4c1d6;
  width: 16px;
  height: auto;
  top: 13px;
  left: 25px;
}
</style>
