export const maxAvailablePagesInDocumentPreview = 500;
export const maxNumberOfPagesInDocumentPreview = 1;

export const supportedDocumentFileExtensions = ['pdf', 'docx', 'xlsx', 'odt', 'odf', 'jpg', 'jpeg', 'png'];
export const supportedSignatureFileExtensions = ['png', 'jpeg', 'jpg'];

// TODO - implement this in the future
export const userTokenKey = 'accessToken';
export const accessRefreshTokenKey = 'accessRefreshToken';
export const userWorkspaceIdKey = 'userWorkspaceId';
